import {Component, Inject, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {SettingsService} from "../../../services/external/settings.service";
import {take} from "rxjs/operators";
import {NewsletterService} from "../../../services/external/newsletter.service";
//import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'ngx-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  html: any;
  newsletterId: any;
  title: any;
  preview: any;
  group;
  type;
  industry = [];
  groups;
  types;
  industries;
  testMail = false;
  massMail = false;
  testMailAddress = '';
  testBulkMailAddress = 'bounce@simulator.amazonses.com';
  sending = false;
  sent = false;

  constructor(
    protected dialogRef: NbDialogRef<any>,
    private settingsService: SettingsService,
    private newsletterService: NewsletterService
    //@Inject(MAT_DIALOG_DATA) data: any
  ) {
    //console.log(data);
  }

  ngOnInit(): void {
    this.getSelections();
  }

  getSelections() {
    this.settingsService.getSetting('newsletter').valueChanges().pipe(take(1)).subscribe((newsletter) => {
      if (newsletter) {
        this.groups = newsletter.group;
      }
    });
    this.settingsService.getSetting('type').valueChanges().pipe(take(1)).subscribe((type) => {
      if (type) {
        this.types = type.values;
      }
    });
    this.settingsService.getSetting('industry').valueChanges().pipe(take(1)).subscribe((industry) => {
      if (industry) {
        this.industries = industry.values;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  toggleTestMail(testMail: boolean) {
    this.testMail = testMail;
    if (!testMail) {
      this.massMail = false;
    }
  }

  toggleMassMail(massMail: boolean) {
    this.massMail = massMail;
  }

  send() {
    if (!this.group && !this.type && this.industry.length <= 0 && !this.testMail) {
      return;
    }
    this.sending = true;
    this.sent = false;
    if (!this.testMail && this.group && !this.type && this.industry.length <= 0) {
      const group = this.groups.filter(group => {
        return group.id == this.group;
      });
      if(group.length > 0) {
        this.newsletterService.sendNewsletter(this.newsletterId, group[0].industries, null, group[0].includeNull, 1, false, false, null).subscribe((sent) => {
          console.log(sent);
          this.sent = true;
          this.sending = false;
        }, (err) => {
          console.log('Error in sending mail.', err);
          this.sending = false;
        });
      } else {
        this.sending = false;
      }
    } else if (!this.testMail && !this.group && (this.type || this.industry.length > 0)) {
      let industries = null;
      if (this.industry.length > 0) {
        industries = this.industry;
      }

      this.newsletterService.sendNewsletter(this.newsletterId, industries, this.type, false, 1, false, false, null).subscribe((sent) => {
        console.log(sent);
        this.sent = true;
        this.sending = false;
      }, (err) => {
        console.log('Error in sending mail.', err);
        this.sending = false;
      });
    } else if (this.testMail) {
      let industries = null;
      let toAddress = this.testMailAddress;
      if (this.massMail) {
        if (this.industry.length > 0) {
          industries = this.industry;
        }
        // success@simulator.amazonses.com
        // bounce@simulator.amazonses.com
        // complaint@simulator.amazonses.com
        toAddress = this.testBulkMailAddress;
      }
      this.newsletterService.sendNewsletter(this.newsletterId, industries, null, false, 1, true, this.massMail, toAddress).subscribe((sent) => {
        console.log(sent);
        this.sent = true;
        this.sending = false;
      }, (err) => {
        console.log('Error in sending mail.', err);
        this.sending = false;
      });
    }
  }
}
