import { Component, OnInit } from '@angular/core';
import {LocalDataSource} from "ng2-smart-table";
import {take} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'ngx-duplicate-modal',
  templateUrl: './duplicate-modal.component.html',
  styleUrls: ['./duplicate-modal.component.scss']
})
export class DuplicateModalComponent implements OnInit {

  customers;

  customerSource: LocalDataSource;
  customerSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      email: {
        title: 'E-Mail',
        filter: false,
        type: 'html'
      },
      company: {
        title: 'Firma',
        filter: false
      },
      fullName: {
        title: 'Name',
        filter: false
      },
      postalCode: {
        title: 'PLZ',
        filter: false
      },
      city: {
        title: 'Ort',
        filter: false
      }
    }
  };

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.customers);

    this.customerSource = new LocalDataSource();
    let preparedResults = [];
    this.customers.forEach((customer) => {
      preparedResults.push(this.prepareCustomerSearchresult(customer));
    });
    this.customerSource.load(preparedResults);
    this.customerSource.reset();
  }

  prepareCustomerSearchresult(customer) {
    let fullName = '';
    if (customer.address.pre_name) {
      fullName = fullName + customer.address.pre_name;
    }
    if (customer.address.name) {
      let lb = '';
      if(fullName.length > 0) {
        lb = ' ';
      }
      fullName = fullName + lb + customer.address.name;
    }
    let email = customer.communication.email;
    if (customer.communication.billingEmail && customer.communication.billingEmail.length > 0 &&
      email != customer.communication.billingEmail) {
      email = email + '<br />' + customer.communication.billingEmail;
    }
    if (customer.communication.ccBillingEmails && customer.communication.ccBillingEmails.length > 0 &&
      email != customer.communication.ccBillingEmails) {
      email = email + '<br />' + customer.communication.ccBillingEmails;
    }
    let searchResult = {
      id: customer.id,
      email: email,
      billingEmail: customer.communication.billingEmail,
      company: customer.address.company,
      fullName: fullName,
      postalCode: customer.address.postal_code,
      city: customer.address.city
    };
    return searchResult;
  }

  goToDuplicateCustomer(event) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/customer/' + event.data.id])
    );
    window.open(url, '_blank');
  }
}
