import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  // sevdeskApi = 'http://localhost:5001/hospiz-crm/europe-west1/';
  sevdeskApi = 'https://europe-west1-hospiz-crm.cloudfunctions.net/';

  constructor(
    private httpClient: HttpClient
  ) { }

  createCustomer(customerId) {
    return this.httpClient.post(this.sevdeskApi + 'createContactInSevdesk', {
      customerId: customerId
    });
  }

  /**
   * Create bill
   *
   * Customer ID and Subscription ID and Type 1 -> create specific subscription bill for customer
   * Customer ID and Type 1 -> create all subscription bills for customer
   * Customer ID and Type 2 -> create member bill fo customer
   * Only Type 1 -> create billing for all active subscriptions
   * Only Type 2 -> create billing for all active members
   *
   * @param customerId
   * @param subscriptionId
   * @param type
   * @param year
   * @param date
   * @returns {Observable<Object>}
   */
  createBillForCustomer(customerId, subscriptionId, type, year, date) {
    return this.httpClient.post(this.sevdeskApi + 'createBillInSevdesk', {
      type: type,
      year: year,
      date: date,
      customerId: customerId,
      subscriptionId: subscriptionId
    });
  }

  createActiveBills(magazineId, type, year, date) {
    return this.httpClient.post(this.sevdeskApi + 'createBillsInSevdesk', {
      type: type,
      year: year,
      date: date,
      magazineId: magazineId
    });
  }

  deleteBills(client, token, from, to, pre) {
    return this.httpClient.post(this.sevdeskApi + 'deleteBillsInSevdesk', {
      client: client,
      token: token,
      from: from,
      to: to,
      pre: pre
    });
  }

  updateContactManuallyInSevdesk(customerId) {
    return this.httpClient.put(this.sevdeskApi + 'updateContactManuallyInSevdesk', {
      customerId: customerId
    });
  }

  sendBillsViaSevdesk(tagTitle) {
    return this.httpClient.post(this.sevdeskApi + 'sendBillsViaSevdesk', {
      tagTitle: tagTitle
    });
  }

}
