<div class="row" *ngIf="date">
  <div class="col-md-12">
    <nb-card class="scroll-card">
      <nb-card-header>
        <h6>
          Rechnung exportieren
        </h6>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-md-12">
            <div class="section-block">
              <b>Jahr</b><br />
              <input type="text" nbInput fullWidth placeholder="Jahr" [(ngModel)]="year">
            </div>
          </div>
        </div>
        <div class="row">
          &nbsp;
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-block">
              <b>Datum</b><br />
              <input nbInput fullWidth placeholder="Datum" [nbDatepicker]="dateTimePicker" [(ngModel)]="date">
              <nb-datepicker #dateTimePicker></nb-datepicker>
            </div>
          </div>
        </div>
        <div class="row">
          &nbsp;
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="basic" (click)="export()">Exportieren</button>
      </nb-card-footer>
    </nb-card>
  </div>
</div>
