<div class="row dialog-root" *ngIf="subscription">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <button nbButton status="basic" (click)="close()">Schließen</button>
      </div>
      <div class="col-md-6">
        <button *ngIf="!editFlag && !create" class="float-right" nbButton status="basic" (click)="edit()">Bearbeiten</button>
        <button *ngIf="editFlag && !create" class="float-right" nbButton status="basic" (click)="cancel()">Abbrechen</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <nb-card class="scroll-card">
          <nb-card-header>
            <h6>
              Abodetails
            </h6>
          </nb-card-header>
          <nb-card-body>
            <div *ngIf="!create" class="section-block">
              <h5>{{getMagazineName(subscription.magazine)}}</h5>
              <br /><br />
            </div>

            <div *ngIf="create" class="section-block">
              <br /><b>Zeitschrift</b><br />
              <span>
               <nb-select fullWidth placeholder="Bitte auswählen" (selectedChange)="onSelectMagazine()" [(selected)]="subscription.magazine">
                  <nb-option *ngFor="let magazine of magazines" [value]="magazine.id">{{magazine.name}}</nb-option>
                </nb-select>
              </span>
            </div>

            <div class="section-block" *ngIf="!subscription.member && price">
              <br /><b>Standardpreise</b><br />
              Preis: {{ price | currency }}<br />
              Versand Inland: {{ shippingInland | currency }}<br />
              Versand Ausland: {{ shippingAbroad | currency }}
            </div>

            <div *ngIf="!create" class="section-block">
              <nb-checkbox status="basic" [disabled]="true" [(ngModel)]="isActive"  [ngModelOptions]="{standalone: true}">Abo ist aktiv</nb-checkbox>
            </div>

            <div class="section-block" *ngIf="subscription.subscription_no">
              <br /><b>Abo Nummer</b><br />
              {{getMagazinePreNumber(subscription.magazine)}}{{getSubscriptionNumber(subscription.subscription_no)}}
            </div>

            <div class="section-block">
              <br /><b>Anzahl</b><br />
              <span *ngIf="!editFlag">{{subscription.amount || '-'}}</span>
              <span *ngIf="editFlag">
                <input type="number" [step]="1" nbInput fullWidth placeholder="Anzahl" [(ngModel)]="subscription.amount">
              </span>
            </div>

            <div class="section-block" *ngIf="!subscription.member">
              <br /><b>Rabattart</b><br />
              <span *ngIf="!editFlag">{{subscription.discount_type || '-'}}</span>
              <span *ngIf="editFlag">
                <input type="text" nbInput fullWidth placeholder="Rabattart" [(ngModel)]="subscription.discount_type">
              </span>
            </div>

            <div class="section-block" *ngIf="!subscription.member">
              <br /><b>Rabatt (in Prozent)</b><br />
              <span *ngIf="!editFlag">{{subscription.discount + '%' || '-'}}</span>
              <span *ngIf="editFlag">
                <input type="number" [step]="0.1" nbInput fullWidth placeholder="Rabatt" [(ngModel)]="subscription.discount">
              </span>
            </div>

            <div class="section-block" *ngIf="!subscription.member">
              <br /><b>Rabatt (Fixpreis)</b><br />
              <span *ngIf="!editFlag">{{subscription.discount_fixed + ' ' + subscription.currency || '-'}}</span>
              <span *ngIf="editFlag">
                <input type="number" [step]="0.1" nbInput fullWidth placeholder="Rabatt (Fixpreis)" [(ngModel)]="subscription.discount_fixed">
              </span>
            </div>

            <div class="section-block" *ngIf="subscription.member">
              <br /><b>Preis</b><br />
              In Mitgliedschaft enthalten
            </div>

            <div class="section-block" *ngIf="!subscription.member">
              <br /><b>Versand Sonderkondition</b><br />
              <span *ngIf="!editFlag">{{subscription.shipping + '€' || '-'}}</span>
              <span *ngIf="editFlag">
                <input type="number" [step]="0.1" nbInput fullWidth placeholder="Versand Sonderkondition" [(ngModel)]="subscription.shipping">
              </span>
            </div>

            <div class="section-block" *ngIf="!subscription.member">
              <br />
              <span *ngIf="!editFlag">
                <nb-checkbox status="basic" [disabled]="true" [(ngModel)]="subscription.free_shipping"  [ngModelOptions]="{standalone: true}">Kostenloser Versand</nb-checkbox>
              </span>
              <span *ngIf="editFlag">
                <nb-checkbox status="basic" [(ngModel)]="subscription.free_shipping"  [ngModelOptions]="{standalone: true}">Kostenloser Versand</nb-checkbox>
              </span>
            </div>

            <div class="section-block" *ngIf="!subscription.member">
              <br /><b>Währung</b><br />
              {{subscription.currency || '-'}}
            </div>

            <div class="section-block">
              <br /><b>Bestellzeichen</b><br />
              <span *ngIf="!editFlag">{{subscription.order_code || '-'}}</span>
              <span *ngIf="editFlag">
                <input type="text" nbInput fullWidth placeholder="Bestellzeichen" [(ngModel)]="subscription.order_code">
              </span>
            </div>

            <div class="section-block">
              <br /><b>Abgeschlossen am</b><br />
              <span *ngIf="(!editFlag || subscription.member) && !subscription.date">-</span>
              <span *ngIf="(!editFlag || subscription.member) && subscription.date">
                {{subscription.date.toDate() | date: 'dd.MM.yyyy'}}
              </span>
              <span *ngIf="editFlag && !subscription.member">
                <input nbInput fullWidth placeholder="Abgeschlossen am" [nbDatepicker]="dateTimePickerSubscriptionDate" [(ngModel)]="subscriptionDate">
                <nb-datepicker #dateTimePickerSubscriptionDate></nb-datepicker>
              </span>
            </div>

            <div class="section-block" *ngIf="!subscription.member">
              <br /><b>Kündigung erhalten am</b><br />
              <span *ngIf="!editFlag && !subscription.notice_date">-</span>
              <span *ngIf="!editFlag && subscription.notice_date">
                {{subscription.notice_date.toDate() | date: 'dd.MM.yyyy'}}
              </span>
              <span *ngIf="editFlag">
                <input nbInput fullWidth placeholder="Kündigung erhalten am" [nbDatepicker]="dateTimePickerNoticeDate" [(ngModel)]="subscriptionNoticeDate">
                <nb-datepicker #dateTimePickerNoticeDate></nb-datepicker>
              </span>
            </div>

            <div class="section-block">
              <br /><b>Kündigung zum</b><br />
              <span *ngIf="(!editFlag || subscription.member) && !subscription.subscription_end">-</span>
              <span *ngIf="(!editFlag || subscription.member) && subscription.subscription_end">
                {{subscription.subscription_end.toDate() | date: 'dd.MM.yyyy'}}
              </span>
              <span *ngIf="editFlag && !subscription.member">
                <input nbInput fullWidth placeholder="Kündigung zum" [nbDatepicker]="dateTimePickerEndDate" [(ngModel)]="subscriptionEndDate">
                <nb-datepicker #dateTimePickerEndDate></nb-datepicker>
              </span>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6">
        <nb-card class="scroll-card">
          <nb-card-header>
            <h6>
              Lieferanschrift
            </h6>
          </nb-card-header>
          <nb-card-body>
            <div class="section-block">
              <span *ngIf="!editFlag">
                <nb-checkbox status="basic" [disabled]="true" [(ngModel)]="subscription.address.same_as_billing" [ngModelOptions]="{standalone: true}">Lieferanschrift ist Rechnungsanschrift</nb-checkbox>
              </span>
              <span *ngIf="editFlag">
                <nb-checkbox (change)="switchAddress($event)" status="basic" [(ngModel)]="subscription.address.same_as_billing" [ngModelOptions]="{standalone: true}">Lieferanschrift ist Rechnungsanschrift</nb-checkbox>
              </span>
            </div>

            <div *ngIf="(!editFlag && !create) || (editFlag && !subscription.address.same_as_billing) || (create && !subscription.address.same_as_billing)">
              <div class="section-block">
                <br /><b>Firma</b><br />
                <span *ngIf="!editFlag">{{subscription.address.company || '-'}}</span>
                <span *ngIf="editFlag">
                  <input type="text" nbInput fullWidth placeholder="Versand Sonderkondition" [(ngModel)]="subscription.address.company">
                </span>
              </div>

              <div class="section-block">
                <br /><b>Zusatz</b><br />
                <span *ngIf="!editFlag">{{subscription.address.additional || '-'}}</span>
                <span *ngIf="editFlag">
                  <input type="text" nbInput fullWidth placeholder="Zusatz 1" [(ngModel)]="subscription.address.additional">
                </span>
              </div>

              <div class="section-block">
                <br /><b>Zusatz 2</b><br />
                <span *ngIf="!editFlag">{{subscription.address.additional2 || '-'}}</span>
                <span *ngIf="editFlag">
                  <input type="text" nbInput fullWidth placeholder="Zusatz 2" [(ngModel)]="subscription.address.additional2">
                </span>
              </div>

              <div *ngIf="!editFlag" class="section-block">
                <br /><b>Name</b><br />
                {{getFullName(subscription.address)}}
              </div>

              <div *ngIf="editFlag" class="section-block">
                <br /><b>Anrede</b><br />
                <span>
                 <nb-select fullWidth placeholder="Bitte auswählen" [(selected)]="subscription.address.salutation">
                    <nb-option [value]="">Bitte auswählen</nb-option>
                    <nb-option *ngFor="let salutation of salutations" [value]="salutation.id">{{salutation.name}}</nb-option>
                  </nb-select>
                </span>
              </div>

              <div *ngIf="editFlag" class="section-block">
                <br /><b>Titel</b><br />
                <span>
                  <input type="text" nbInput fullWidth placeholder="Titel" [(ngModel)]="subscription.address.title">
                </span>
              </div>

              <div *ngIf="editFlag" class="section-block">
                <br /><b>Vorname</b><br />
                <span>
                  <input type="text" nbInput fullWidth placeholder="Vorname" [(ngModel)]="subscription.address.pre_name">
                </span>
              </div>

              <div *ngIf="editFlag" class="section-block">
                <br /><b>Nachname</b><br />
                <span>
                  <input type="text" nbInput fullWidth placeholder="Nachname" [(ngModel)]="subscription.address.name">
                </span>
              </div>

              <div *ngIf="editFlag" class="section-block">
                <br /><b>Adresssuche</b><br />
              </div>

              <input #autoInput
                     nbInput
                     fullWidth
                     type="text"
                     (input)="onFindAddress($event)"
                     placeholder="Adresssuche"
                     [nbAutocomplete]="auto"
                     [(ngModel)]="addressSearch"
                     *ngIf="editFlag" />

              <nb-autocomplete #auto (selectedChange)="onSelectAddress($event)">

                <nb-option *ngFor="let location of locations" [value]="location">
                  {{ location.address.label }}
                </nb-option>

              </nb-autocomplete>

              <div *ngIf="subscription.address.label" class="section-block">
                <br /><b>Selektierte Adresse</b><br />
                <span *ngIf="!editFlag">{{subscription.address.label || '-'}}</span>
                <span *ngIf="editFlag">
                  <input [disabled]="subscription.address.label || create" type="text" nbInput fullWidth placeholder="Selektierte Adresse" [(ngModel)]="subscription.address.label">
                </span>
              </div>

              <div class="section-block">
                <div *ngIf="!subscription.address.label && !editFlag">
                  <br /><b>Straße</b><br />
                  <span *ngIf="!editFlag">{{subscription.address.street || '-'}}</span>
                </div>
                <span *ngIf="editFlag">
                  <br /><b>Straße</b><br />
                  <input [disabled]="subscription.address.label || create" type="text" nbInput fullWidth placeholder="Versand Sonderkondition" [(ngModel)]="subscription.address.street">
                </span>
              </div>

              <div *ngIf="!editFlag && !subscription.address.label" class="section-block">
                <br /><b>PLZ Ort</b><br />
                {{getFullAddress(subscription.address)}}
              </div>

              <div *ngIf="editFlag" class="section-block">
                <br /><b>PLZ</b><br />
                <span>
                  <input [disabled]="subscription.address.label || create" type="text" nbInput fullWidth placeholder="PLZ" [(ngModel)]="subscription.address.postal_code">
                </span>
              </div>

              <div *ngIf="editFlag" class="section-block">
                <br /><b>Ort</b><br />
                <span>
                  <input [disabled]="subscription.address.label || create" type="text" nbInput fullWidth placeholder="Ort" [(ngModel)]="subscription.address.city">
                </span>
              </div>

              <div *ngIf="!subscription.address.label" class="section-block">
                <br /><b>Land</b><br />
                <span *ngIf="!editFlag && !subscription.address.label">{{subscription.address.country || '-'}}</span>
                <span *ngIf="editFlag">
                  <input [disabled]="subscription.address.label || create" type="text" nbInput fullWidth placeholder="Land" [(ngModel)]="subscription.address.country">
                </span>
              </div>

              <div class="section-block">
                <br /><b>Postfach</b><br />
                <span *ngIf="!editFlag">{{subscription.address.post_office_box || '-'}}</span>
                <span *ngIf="editFlag">
                  <input type="text" nbInput fullWidth placeholder="Versand Sonderkondition" [(ngModel)]="subscription.address.post_office_box">
                </span>
              </div>

              <div *ngIf="!editFlag" class="section-block">
                <button nbButton status="basic" [cdkCopyToClipboard]="copyAddress(subscription.address)">Lieferanschrift kopieren</button>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
    <div class="row" *ngIf="editFlag" >
      <div class="col-md-12">
        <nb-card>
          <nb-card-body>
            <button class="float-right" nbButton status="basic"
                    [disabled]="!subscription.magazine"
                    (click)="create ? addSubscription() : save()">{{ create ? 'Hinzufügen' : 'Speichern' }}</button>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
</div>
