import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, Query} from "@angular/fire/firestore";
import {Newsletter} from "../../models/newsletter";
import {Observable} from "rxjs/Observable";
import {HttpClient} from "@angular/common/http";
import {firestore} from "firebase";

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  //private newsletterDoc: AngularFirestoreDocument<Newsletter>;
  //newsletter: Observable<Newsletter>;

  //newsletterApi = 'http://localhost:5001/hospiz-crm/europe-west1/';
  newsletterApi = 'https://europe-west1-hospiz-crm.cloudfunctions.net/';

  constructor(
    private afs: AngularFirestore,
    private httpClient: HttpClient
  ) {
  }

  getNewsletterTemplate(): AngularFirestoreDocument<Newsletter> {
    return this.afs.doc<Newsletter>('common/newsletter');
  }

  updateNewsletterTemplate(newsletter: Newsletter) {
    return this.afs.doc<any>('common/newsletter').update(newsletter);
  }

  getNewsletterList() {
    return this.afs.collection('newsletter', ref => ref
      .limit(10)
      .orderBy('created', 'desc'))
  }

  createNewsletter(newsletter: Newsletter) {
    return this.afs.collection('newsletter').add(newsletter);
  }

  getNewsletter(newsletterId: string) {
    return this.afs.collection('newsletter').doc<any>(newsletterId);
  }

  updateNewsletter(newsletter: Newsletter, newsletterId: string) {
    return this.afs.doc<any>('newsletter/' + newsletterId).update(newsletter);
  }

  sendNewsletter(newsletterId: any, industries, type, includeNull, client, isTest, isMassTest, mail) {
    return this.httpClient.post(this.newsletterApi + 'sendNewsletter', {
      newsletterId: newsletterId,
      industries: industries,
      type: type,
      includeNull: includeNull,
      client: client,
      isTest: isTest,
      isMassTest: isMassTest,
      mail: mail
    });
  }

  getNewsletterResults(title: string, date: Date) {
    return this.afs.collection('newsletterresponse', ref => {
      let query : Query = ref;
      query = query.where('aws.mail.commonHeaders.subject', '==', title);
      query = query.where('sentDate', '>', date);
      return query;
    });
  }

  triggerMailQueue() {
    return this.httpClient.post(this.newsletterApi + 'triggerMailQueueOnce', {});
  }

  updateBlacklist() {
    return this.httpClient.put(this.newsletterApi + 'updateBlacklist', {
    });
  }

  getBouncedMails(onlyNotprocessed: boolean, subject) {
    return this.afs.collection('newsletterresponse', ref => {
      let query: Query = ref;
      query = query.where('type', '==', 'bounce');
      if (onlyNotprocessed) {
        query = query.where('processed', '==', false);
      }
      if (subject) {
        query = query.where('aws.mail.commonHeaders.subject', '==', subject);
      }
      return query;
    });
  }

  updateBouncedMails(id, update) {
    return this.afs.doc<any>('newsletterresponse/' + id).update(update);
  }

  unsubscribeCustomer(mail: string) {
    return this.httpClient.put(this.newsletterApi + 'unsubscribeCustomer', {
      mail: mail
    });
  }

  addToBlacklist(mail) {
    const update = {
      values: firestore.FieldValue.arrayUnion(mail)
    };
    return this.afs.doc<any>('blacklist/mails').update(update);
  }

  getMailsInQueue() {
    return this.afs.collection('mailqueue', ref => {
      let query : Query = ref;
      return query;
    });
  }

}
