import {Inject, Injectable, Injector} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import {NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthService, NbAuthToken} from "@nebular/auth";
import {switchMap} from "rxjs/operators";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: NbAuthService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    /*if (!this.filter(req)) {
      return this.authService.isAuthenticatedOrRefresh()
        .pipe(
          switchMap(authenticated => {
            if (authenticated) {*/
              if (req.url.includes('hereapi.com')) {
                return next.handle(req);
              } else {
                return this.authService.getToken().pipe(
                  switchMap((token: NbAuthToken) => {
                    const JWT = `Bearer ${token.getValue()}`;
                    req = req.clone({
                      setHeaders: {
                        Authorization: JWT,
                      },
                    });
                    return next.handle(req);
                  }),
                );
              }
            /*} else {
              // Request is sent to server without authentication so that the client code
              // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
              return next.handle(req);
            }
          }),
        )
    } else {
      return next.handle(req);
    }*/
  }

}
