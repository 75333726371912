import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

declare var H: any;

@Injectable({
  providedIn: 'root',
})
export class AddressesService {

  private hereApi = 'https://geocode.search.hereapi.com/v1/';
  private key = '&apiKey=h3_6zzw-ONmoxLI-DdVQNV5_LY4FYjddwzeJIPdox80';

  public platform: any;
  public geocoder: any;

  public constructor(
    private http: HttpClient,
  ) {
  }

  public getAddress(query: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.hereApi + 'geocode?q=' + query + this.key).subscribe((result: any) => {
        if (result.items.length > 0) {
          resolve(result.items);
        } else {
          reject({ message: "no results found" });
        }
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getAddressFromLatLng(query: string) {
    return new Promise((resolve, reject) => {
      this.geocoder.reverseGeocode({ prox: query, mode: "retrieveAddress" }, result => {
        if(result.Response.View.length > 0) {
          if(result.Response.View[0].Result.length > 0) {
            resolve(result.Response.View[0].Result);
          } else {
            reject({ message: "no results found" });
          }
        } else {
          reject({ message: "no results found" });
        }
      }, error => {
        reject(error);
      });
    });
  }

  public matchCountry(country: string) {
    if (country === 'DEU') {
      return 'DE';
    } else if (country === 'AUT') {
      return 'AT';
    } else if (country === 'BEL') {
      return 'BE';
    } else if (country === 'CHE') {
      return 'CH';
    } else if (country === 'ESP') {
      return 'ES';
    } else if (country === 'FRA') {
      return 'FR';
    } else if (country === 'GRC') {
      return 'GR';
    } else if (country === 'IRL') {
      return 'IR';
    } else if (country === 'ITA') {
      return 'IT';
    } else if (country === 'LTU') {
      return 'LI';
    } else if (country === 'LUX') {
      return 'LU';
    } else if (country === 'NLD') {
      return 'NL';
    } else if (country === 'NOR') {
      return 'NO';
    } else if (country === 'SWE') {
      return 'SE';
    }
  }

  public getFullNameAddress(address, salutations) {
    let addressString: string = '';

    if(null !== address.company) {
      addressString = addressString + address.company + '\n';
    }

    if(null !== address.additional && address.additional !== '') {
      addressString = addressString + address.additional + '\n';
    }

    if(null !== address.additional2 && address.additional2 !== '') {
      addressString = addressString + address.additional2 + '\n';
    }

    if(null !== address.salutation && address.salutation !== 0) {
      addressString = addressString + this.getSalutationName(salutations, address.salutation) + ' ';
    }

    if(null !== address.title && address.title !== '') {
      addressString = addressString + address.title + ' ';
    }

    let preName = false;
    if(null !== address.pre_name && address.pre_name !== '') {
      addressString = addressString + address.pre_name;
      preName = true;
    }

    let name = false;
    if(null !== address.name && address.name !== '') {
      if(preName) {
        addressString = addressString + ' ';
      }
      addressString = addressString + address.name;
      name = true;
    }

    if(preName || name) {
      addressString = addressString + '\n';
    }

    if(null !== address.street && address.street !== '') {
      addressString = addressString + address.street + '\n';
    }

    if(null !== address.post_office_box && address.post_office_box !== '') {
      addressString = addressString + address.post_office_box + '\n';
    }

    if(null !== address.country && address.country !== '') {
      addressString = addressString + address.country + ' - ';
    }

    let postalCode = false;
    if(null !== address.postal_code && address.postal_code !== '') {
      addressString = addressString + address.postal_code;
      postalCode = true;
    }

    let city = false;
    if(null !== address.city && address.city !== '') {
      if(postalCode) {
        addressString = addressString + ' ';
      }
      addressString = addressString + address.city;
      city = true;
    }

    return addressString;
  }

  getFullName(address: any, salutations) {
    let fullName: string = this.getSalutationName(salutations, address.salutation) + ' ' + ( address.title || '' ) + ' ' + ( address.pre_name || '' ) + ' ' + ( address.name || '' );
    if (fullName === '   ') {
      fullName = '-';
    }
    return fullName;
  }

  getFullAddress(address: any) {
    let fullAddress: string = ( address.postal_code || '' ) + ' ' + ( address.city || '' );
    if (fullAddress === ' ') {
      fullAddress = '-';
    }
    return fullAddress;
  }

  getSalutationName(salutations, salutationId) {
    const salutation = salutations.filter(salutation => {
      return salutation.id == salutationId;
    });
    if(salutation.length > 0) {
      return salutation[0].name;
    } else {
      return '';
    }
  }
}
