import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, Query} from "@angular/fire/firestore";
import {AngularFireFunctions} from "@angular/fire/functions";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor(
    private afs: AngularFirestore,
    private functions: AngularFireFunctions
  ) { }

  getSubscriptions(activeOnly: boolean) {
    return this.afs.collection('subscriptions', ref => {
      let query : Query = ref;
      if(activeOnly) {
        query = query.where('subscription_end', '<', new Date());
      }

      return query;
    });
  }

  getSubscriptionsByCustomer(customerId: string) {
    return this.afs.collection('subscriptions', ref => ref.where('customer', '==', customerId));
  }

  /**
   * type 0 = all
   * type 1 = subscription_end after now
   * type 2 = no subscription_end date
   *
   * @param {string} magazineId
   * @param {number} type
   * @returns {AngularFirestoreCollection<any>}
   */
  getSubscriptionsByMagazine(magazineId: number, type: number) {
    return this.afs.collection('subscriptions', ref => {
      let query : Query = ref;
      query = query.where('magazine', '==', magazineId);
      if(type === 1) {
        query = query.where('subscription_end', '>', new Date());
      } else if(type === 2) {
        query = query.where('subscription_end', '==', null);
      }

      return query;
    });
  }

  getSubscriptionsWhere(queries, order, start, end) {
    return this.afs.collection('subscriptions', ref => {
      let query : Query = ref;
      for(let singleQuery of queries) {
        query = query.where(singleQuery.fieldPath, singleQuery.opStr, singleQuery.value);
      }
      if(order) {
        query = query.orderBy(order);
      }
      if(start) {
        query = query.startAt(start);
      }
      if(end) {
        query = query.endAt(end);
      }
      return query;
    });
  }

  getSubscription(subscriptionId): AngularFirestoreDocument<any> {
    return this.afs.collection('subscriptions').doc<any>(subscriptionId);
  }

  createSubscription(subscription: any) {
    return this.afs.collection('subscriptions').add(subscription);
  }

  updateSubscription(subscriptionId: string, subscription: any) {
    delete subscription.id;
    return this.afs.doc<any>('subscriptions/' + subscriptionId).update(subscription);
  }

  deleteSubscription(subscriptionId: any) {
    return this.afs.doc<any>('subscriptions/' + subscriptionId).delete();
  }
}
