<nb-card class="scrollable">
  <nb-card-header>{{ preview ? 'Vorschau' : 'Versenden' }}: {{ title }}</nb-card-header>
  <nb-card-body>
    <div *ngIf="preview" [innerHTML]="html | safeMessage : 'html'"></div>
    <div *ngIf="!preview">
      <div *ngIf="!testMail && !type && industry.length <= 0" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="label">Gruppe</label><br />
            <nb-select fullWidth placeholder="Gruppe" [(selected)]="group">
              <nb-option value="">Gruppe auswählen</nb-option>
              <nb-option *ngFor="let group of groups" [value]="group.id">{{group.name}}</nb-option>
            </nb-select>
          </div>
        </div>
      </div>
      <div *ngIf="!testMail && !group && !type && industry.length <= 0" class="row or">
        <div class="col-sm-12">
          <br />
          <label class="label">oder</label>
          <hr>
        </div>
      </div>
      <div *ngIf="!testMail && !group" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="label">Typ</label><br />
            <nb-select fullWidth placeholder="Typ" [(selected)]="type">
              <nb-option value="">Typ auswählen</nb-option>
              <nb-option *ngFor="let type of types" [value]="type.id">{{type.name}}</nb-option>
            </nb-select>
          </div>
        </div>
      </div>
      <div *ngIf="(!testMail && !group) || massMail" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="label">Branche</label><br />
            <nb-select fullWidth multiple placeholder="Branche" [(selected)]="industry">
              <nb-option *ngFor="let industry of industries" [value]="industry.id">{{industry.name}}</nb-option>
            </nb-select>
          </div>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <nb-checkbox (checkedChange)="toggleTestMail($event)" [(ngModel)]="testMail">Test E-Mail senden</nb-checkbox>
            </div>
          </div>
          <div *ngIf="testMail" class="row">
            <div class="col-sm-12">
              <nb-checkbox (checkedChange)="toggleMassMail($event)" [(ngModel)]="massMail">Massenmail senden</nb-checkbox>
              <br /><br />
            </div>
          </div>
          <div *ngIf="testMail && !massMail" class="row">
            <input type="text" nbInput fullWidth status="basic" placeholder="Empfänger eingeben" [(ngModel)]="testMailAddress">
          </div>
        </div>
      </div>
      <div *ngIf="massMail" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="label">Empfänger</label><br />
            <nb-select fullWidth placeholder="Empfänger" [(selected)]="testBulkMailAddress">
              <nb-option value="bounce@simulator.amazonses.com">bounce@simulator.amazonses.com</nb-option>
              <nb-option value="success@simulator.amazonses.com">success@simulator.amazonses.com</nb-option>
              <nb-option value="complaint@simulator.amazonses.com">complaint@simulator.amazonses.com</nb-option>
            </nb-select>
          </div>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <br /><br />
          <button [disabled]="sending" nbButton status="danger" (click)="send()">Newsletter versenden</button>
        </div>
      </div>
      <div *ngIf="sent" class="row">
        <div class="col-sm-12 text-center">
          <br /><br />
          <span><b>Der Newsletter wurde versandt</b></span>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <div class="form-group col-sm-6">
        <button nbButton (click)="close()">Schließen</button>
      </div>
      <div class="col-sm-6 text-right">
        <button nbButton (click)="preview = !preview">{{ preview ? 'Versenden' : 'Vorschau' }}</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
