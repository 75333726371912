<div class="row dialog-root">
  <div class="col-md-12">
    <nb-card class="scroll-card">
      <nb-card-header>
        <h6>
          Mögliche Duplikate
        </h6>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-md-12">
            <ng2-smart-table class="customer-result-table"
                             [settings]="customerSettings"
                             [source]="customerSource"
                             (userRowSelect)="goToDuplicateCustomer($event)">
            </ng2-smart-table>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
