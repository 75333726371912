import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {BillingService} from "../../../services/external/billing.service";

@Component({
  selector: 'ngx-billing-modal',
  templateUrl: './billing-modal.component.html',
  styleUrls: ['./billing-modal.component.scss']
})
export class BillingModalComponent implements OnInit {

  year;
  date;
  customerId;
  subscriptionId;
  type;

  constructor(
    protected ref: NbDialogRef<BillingModalComponent>,
    private billingService: BillingService
  ) { }

  ngOnInit(): void {
    this.year = parseInt(new Date().getFullYear().toString().substr(-2));
    this.date = new Date();
  }

  close() {
    this.ref.close();
  }

  export() {
    this.billingService.createBillForCustomer(this.customerId, this.subscriptionId, this.type, this.year, this.date).subscribe((bill) => {
      console.log(bill);
      this.close();
    }, (err) => {
      console.log(err);
    })
  }

}
