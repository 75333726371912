import { NgModule }      from '@angular/core';
import {SafeMessagePipe} from "./safe-message.pipe";

@NgModule({
  imports:        [],
  declarations:   [SafeMessagePipe],
  exports:        [SafeMessagePipe],
})

export class PipeModule {

  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
