import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class MagazinesService {

  constructor(
    private afs: AngularFirestore
  ) { }

  getMagazines() {
    return this.afs.collection('magazines');
  }

  getMagazine(id: string) {
    return this.afs.collection('magazines').doc(id);
  }

  addMagazine(magazine: any) {
    return this.afs.collection('magazines').add(magazine);
  }

  updateMagazine(data: any, magazineId: string) {
    return this.afs.doc<any>('magazines/' + magazineId).update(data);
  }

  deleteMagazine(magazineId: string) {
    return this.afs.doc<any>('magazines/' + magazineId).delete();
  }

}
