import { Injectable } from '@angular/core';
import {Newsletter} from "../../models/newsletter";
import {AngularFirestore} from "@angular/fire/firestore";
import { firestore } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private afs: AngularFirestore
  ) { }

  getSettings() {
    return this.afs.collection('settings');
  }

  createSetting(setting: any) {
    return this.afs.collection('settings').add(setting);
  }

  getSetting(settingId: string) {
    return this.afs.collection('settings').doc<any>(settingId);
  }

  updateSetting(setting: any, settingId: string) {
    return this.afs.doc<any>('settings/' + settingId).update(setting);
  }

  addSettingEntry(newEntry, id) {
    const update = {
      values: firestore.FieldValue.arrayUnion(newEntry)
    };
    return this.afs.doc<any>('settings/' + id).update(update);
  }

  removeSettingEntry(entry, id) {
    const update = {
      values: firestore.FieldValue.arrayRemove(entry)
    };
    return this.afs.doc<any>('settings/' + id).update(update);
  }
}
